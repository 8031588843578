<template>
	<div class="text-center mt-8 mb-4">
		
	</div>
</template>
<script>
export default {

  name: 'Home',

}
</script>
